import React from 'react';
import { BsBalloonHeartFill } from "react-icons/bs";

function Footer(props) {
    return (
        <div className="mt-20 mb-6 w-48 mx-auto">
            <h1>
                <span>coded with </span>
                <BsBalloonHeartFill className="inline"  />
                <span>  by <span className="font-bold text-blue-600"><a href="https://github.com/sagargouda">me</a></span>  </span>
            </h1>
        </div>
    );
}

export default Footer;
