import React, {useState} from 'react';
import {  signInWithPopup ,signOut  } from "firebase/auth";
import {auth, db} from '../firebase'
import { provider} from "../firebase";
import {useDispatch, useSelector} from "react-redux";
import {addUser, removeUser} from "../redux/userSlice";
import {useNavigate} from "react-router-dom";
import {addDoc  , collection , serverTimestamp ,onSnapshot, getDocs} from 'firebase/firestore'


function Guestbook(props) {
    const [message , setMessage] = useState('')
    const [allMessage , setAllMessage] = useState([])
    const dispatch = useDispatch()

    const user = useSelector(store => store.user.userInfo)

    const navigate = useNavigate()
   function login(){
       signInWithPopup(auth, provider)
           .then((result) => {
               const user = result.user;
              console.log(user)
               dispatch(addUser({
                   name: user.displayName,
                   uid: user.uid,
                   email: user.email
               }))

           }).catch((error) => {

           const errorCode = error.code;
           const errorMessage = error.message;
           alert("sorry , some error with signing you in" + " " + errorMessage)
       });
    }

    function signout(){
        signOut(auth).then(() => {
           dispatch(removeUser())

        }).catch((error) => {
           alert('I hate Internet for this reason , some sign out issues ')
        });

    }

    function sendMessage() {
        // Check if the message is not empty
        if (message.trim() !== '') {
            // Add the message to the database
            addDoc(collection(db, "messages"), {
                username: user.name,
                message: message,
                timestamp: serverTimestamp()
            })
                .then(() => {
                    // Clear the message input field after sending
                    setMessage('');
                })
                .catch((error) => {
                    console.error("Error adding message: ", error);
                });
        } else {
            // If the message is empty, display an alert
            alert("Please enter a message.");
        }
    }

// Set up a listener for real-time updates
    onSnapshot(collection(db, "messages"), (querySnapshot) => {
        const messages = [];
        querySnapshot.forEach((doc) => {
            // Push each message to the messages array
            messages.push({
                id: doc.id,
                data: doc.data()
            });
        });
        // Set the state with all messages
        setAllMessage(messages);
    }, (error) => {
        console.error("Error fetching messages: ", error);
    });


    return (

        <div className="w-11/12 max-w-[80%] mx-auto shadow-lg sm:w-3/4 md:w-3/4 p-4 mt-10">
            <h1 className="text-blue-600 text-center font-bold text-lg mb-6">sign in , be my guest</h1>
            {/* Conditionally render sign-in button or input box and sign-out button */}
            {user ? (
                <>
                    <div className="relative flex flex-col items-center w-full max-w-[24rem] mx-auto">
                        <div className="relative h-10 w-full min-w-[200px]">
                            <input
                                type="text"
                                className="peer h-full w-full rounded-[7px] border border-gray-200 border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-gray-200 placeholder-shown:border-t-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-none disabled:border-0 disabled:bg-gray-50"
                                placeholder=" "
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <label
                                className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-gray-500">
                                Your Message
                            </label>
                        </div>
                        <button
                            className="absolute top-[48px] left-2 cursor-pointer text-red-700 text-xs z-10"
                            onClick={signout}
                        >
                            Sign Out
                        </button>
                        <button
                            className="absolute right-1 top-1 select-none rounded bg-blue-500 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85 disabled:pointer-events-none disabled:opacity-50"
                            type="button"
                            onClick={sendMessage}
                        >
                            Send
                        </button>
                    </div>


                </>

            ) : (

                <button onClick={login}
                        className="px-4 mx-auto items-center py-2 border flex gap-2 border-gray-300 dark:border-gray-700 rounded-lg text-gray-700 dark:text-gray-200 hover:border-gray-400 dark:hover:border-gray-500 hover:text-gray-900 dark:hover:text-gray-300 hover:shadow transition duration-150">
                    <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy"
                         alt="Google logo"/>
                    <span className="text-sm text-black">Login with Google</span>
                </button>

            )}
            {/* Display guestbook entries */}
            {
                <div
                    className="mt-10  w-full mx-auto shadow-lg sm:w-[500px] md:w-[500px] lg:w-[700px] xl:w-[900px] p-4">
                    <h1 className="font-bold mb-3 text-red-700">Previous Guests</h1>
                    {allMessage.map((msg) => (
                        <div key={msg.id} className="flex w-11/12  flex-row items-start mb-6">
                            <div className="pr-4 ">
                                <p className="text-sm font-semibold">{msg.data.username }:</p>
                            </div>
                            <div className="bg-blue">
                                <p className="text-sm">{msg.data.message}</p>
                            </div>
                        </div>
                    ))}
                </div>


            }


        </div>


    );
}

export default Guestbook;