import React from 'react';
import {Link} from "react-router-dom";
import sa7 from '../portfolioImages/sa-7-min.jpg';
import Projects from "./Projects";


function Body() {
    return (
        <div className="w-[100%] mt-16 sm:mt-20 md:mt-20   flex flex-col text-center  sm:justify-center md:justify-center mx-auto">
            <p className="text-lg sm:4xl md:text-5xl font-bold">Hey, I am <span className="text-blue-600">Vidyasagar Reddy</span></p>
            <div className="md:w-[50%] w-[80%] mt-10 mx-auto">
                <p className="text-lg  md:text-lg font-medium">I'm a <span className="font-bold text-blue-600">Frontend engineer</span> specializing in Crafting exceptional digital
                    experiences check out <Link to={'/about'}> <span
                        className="text-blue-600  font-bold">about</span></Link> section for more </p>
            </div>

            {/* projects section*/}
            <Projects/>


            <div className="md:w-[50%] w-[80%] mt-20 mx-auto">
                <p className="text-lg  md:text-lg font-medium">Custom <span
                    className='font-bold text-red-600'>Oil</span> Painting made by me on Photoshop</p>
                <Link to="/designs"> <img src={sa7} alt="WuzvL.jpg"
                                          className="mt-4 mx-auto sm:w-[50%] md:w-[50%]" title="checkout design page"/></Link>


            </div>


        </div>
    );
}

export default Body;