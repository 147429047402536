
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore'


const firebaseConfig = {
    apiKey: "AIzaSyD8wf6EiH_0nhuEooYxRIT4jEw9B_JDkKA",
    authDomain: "portfolio-a7083.firebaseapp.com",
    projectId: "portfolio-a7083",
    storageBucket: "portfolio-a7083.appspot.com",
    messagingSenderId: "824696420244",
    appId: "1:824696420244:web:83c9f7664bc2f31dfc4181",
    measurementId: "G-N15V4HVCTR",

};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)
export const provider = new GoogleAuthProvider();
