import React from 'react';
import Header from "./Header";

import Footer  from './Footer'
import {Outlet} from "react-router-dom";

function Main(props) {

    return (
        <div >
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    );
}

export default Main;