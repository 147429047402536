import React from 'react';
// Importing icons
import { FaHtml5, FaCss3, FaReact, FaGit, FaFigma } from 'react-icons/fa';
import { IoLogoJavascript, IoLogoFirebase } from 'react-icons/io5';
import { SiTailwindcss, SiRedux, SiJest, SiExpress, SiAdobephotoshop, SiBlender, SiAseprite } from 'react-icons/si';
import { GoTerminal } from 'react-icons/go';
import { DiIllustrator } from "react-icons/di";
import { SiCanva } from "react-icons/si";

const skillsCategories = {
    Frontend: [
        { icon: FaHtml5, title: "HTML" },
        { icon: FaCss3, title: "CSS3" },
        { icon: IoLogoJavascript, title: "JavaScript" },
        { icon: SiTailwindcss, title: "Tailwind" },
        { icon: FaReact, title: "React" },
        { icon: SiRedux, title: "Redux" },
    ],
    Backend: [
        { icon: SiExpress, title: "Express" },
        { icon: IoLogoFirebase, title: "Firebase" },
    ],
    Testing: [
        { icon: SiJest, title: "Jest Testing" },
    ],
    Design: [
        { icon: FaFigma, title: "Figma" },
        { icon: SiAdobephotoshop, title: "Photoshop" },
        { icon: SiAseprite, title: "Aseprite" },
        { icon:DiIllustrator , title: "illustrator" },
        { icon:SiCanva , title: "Canva" },
    ],
    Tools: [
        { icon: FaGit, title: "Git" },
        { icon: GoTerminal, title: "Terminal" },
    ],
};

function SkillButton({ Icon, title }) {
    return (
        <div className="flex flex-col items-center">
            <button className="text-2xl"><Icon /></button>
            <span className="text-sm mt-2">{title}</span>
        </div>
    );
}

function SkillsSection() {
    return (
        <div>
            <h1 className="text-red-600 font-bold text-3xl mt-20 text-center relative inline-block">
                Skills
                <span className="absolute -bottom-2 left-0 w-full h-1 bg-red-600"></span>
            </h1>

            {Object.entries(skillsCategories).map(([category, skills]) => (
                <div key={category}>
                    <h2 className="text-xl font-bold mt-10  text-blue-600 text-center">{category}</h2>
                    <div className="flex justify-center flex-wrap gap-5 mt-5">
                        {skills.map(({icon: Icon, title}) => (
                            <SkillButton key={title} Icon={Icon} title={title}/>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default SkillsSection;
