import React from 'react';

import { MdPreview } from "react-icons/md";
import { FaGithub } from "react-icons/fa";

function ProjectList({name , summary , image , github ,live}) {
    return (
        <div
            className="max-w-full break-words text-wrap  mx-auto shadow-lg h-auto sm:w-2/4 md:w-1/4 p-4 rounded-lg mt-10  border-2 flex flex-col justify-between">
            <h1 className="font-bold text-2xl">{name}</h1>
            <p className="font-medium text-md text-gray-500 text-sm mt-3">{summary}</p>

            <img className="mt-4 rounded-lg" src={image} alt=""/>
            <div className="flex flex-row mx-auto gap-5 mt-5">
                <a href={live} target="_blank" rel="noreferrer"> <MdPreview size="20" title="Live Demo"/></a>
                <a href={github} target="_blank" rel="noreferrer"><FaGithub size="20" title="Github"/></a>
            </div>
        </div>
    );
}

export default ProjectList;