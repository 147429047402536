import React from 'react';
import profile from '../portfolioImages/saimage.jpg'


function About(props) {
    return (
        <div className="w-11/12 mx-auto shadow-lg h-auto sm:w-4/4 md:w-4/4 lg:w-2/4 xl:w-2/4 p-4 mt-10">
            <div className="flex items-center justify-center mb-6">
                <img className="w-32 h-auto rounded-full" src={profile} alt="Sagar"/>
            </div>
            <div className="whitespace-normal">
                <div className="mb-4 p-4 rounded-lg shadow-md" style={{
                    backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.3)'
                }}>
                    <p className="font-medium text-gray-700 text-lg">
                        Hey, welcome back! Let me tell you a bit about myself. I started designing about 3 years ago
                        when I was around 19 ,Initially I collaborated with many underground hip-hop artists, helping them with their album covers and Spotify canvases.
                    </p>
                </div>
                <div className="mb-4 p-4 rounded-lg shadow-md" style={{
                    backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.3)'
                }}>
                    <p className="font-medium text-gray-700 text-lg">
                        Over time, I learnt a bit of 3D design using Blender and even created a bunch of NFTs for fun...
                    </p>
                </div>
                <div className="mb-4 p-4 rounded-lg shadow-md" style={{
                    backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.3)'
                }}>
                    <p className="font-medium text-gray-700 text-lg">
                        However, during my 6th semester in college, I realized my interest in coding when I saw people online creating games and generative art (p5.js),  That's when I began learning HTML, CSS, and JavaScript. Discovering React was a game-changer for

                    </p>
                </div>
                <div className="p-4 rounded-lg shadow-md" style={{
                    backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.3)'
                }}>
                    <p className="font-medium text-gray-700 text-lg">
                        <span className="text-red-600">I would love to work with people who are creating stuff that makes this world a better place.</span>
                    </p>
                </div>
            </div>
        </div>


    );
}

export default About;