import React from 'react';
import ProjectList from "./ProjectList";
import project1 from '../portfolioImages/saproject-1-min.png'
import project2 from '../portfolioImages/saproject-2-min.png'
import project3 from '../portfolioImages/saproject-3-min.png'
import project4 from '../portfolioImages/saproject-4-min.png'
import project5 from '../portfolioImages/saproject-5-min.png'
import project6 from '../portfolioImages/saproject-6-min.png'
import SkillSection from "./SkillSection";
function Projects() {
    return (
        <div
            className="w-[80%] md:w-[80%] sm:w-[80%]  mx-auto mt-2 sm:mt-25 md:mt-25   flex flex-col  gap-4 text-center  sm:justify-center md:justify-center ">


                <h1 className="text-red-600 font-bold text-3xl mt-16 text-center relative inline-block">
                    Projects
                    <span
                        className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-[45%] md:w-[9%] sm:w-[20%] h-1 bg-red-600"></span>
                </h1>



            <div className="w-[100%]  mx-auto gap-4 flex  flex-wrap ">
                <ProjectList image={project3} name={"Youtube Downloader"}
                             github={"https://github.com/sagargouda/youtubeDownloader"}
                             live={"https://youtube-downloader-mu.vercel.app/"}
                             summary={"Youtube shorts and video Downloader , Download any youtube video for free without ease"}/>
                <ProjectList image={project1} name={"Linkz"}
                             live={"https://linkzzz.vercel.app/"}
                             github={"https://github.com/sagargouda/link-summerizer"}
                             summary={"Ai article summarizer which can summarize articles for you and can save time"}/>
                <ProjectList image={project2} name={"NetflixGpt"}
                             live={"https://netflixgpt-b5cb5.web.app/"}
                             github={"https://github.com/sagargouda/netflixgpt-movieSearchMadeEasy"}
                             summary={"Movie recommendation for netflix with the help of Ai"}/>
                <ProjectList image={project5} name={'Impresstweets'}
                             live={"https://impresstweet.vercel.app/"}
                             github={"https://github.com/sagargouda/impresstweets"}
                             summary={"A tweet generator website where you create tweets share it to your freinds"}/>
                <ProjectList image={project4} name={"HeyYoutube"}
                             github={"https://github.com/sagargouda/heyyoutube"}
                             summary={"Youtube Like App With Better optimized search feature than flipkart"}/>
                <ProjectList image={project6} name={"speechhelper"}
                             live={"https://speechhelper.vercel.app/"}
                             github={"https://github.com/sagargouda/speechhelper"}
                             summary={"speech translate web app which supports 15 languages"}/>

            </div>

            {/*     skills section */}

            <SkillSection/>

        </div>
    )
        ;
}

export default Projects;