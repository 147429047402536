import React from 'react';

function Error(props) {
    return (
        <div>
            <h1>404 error wrong route buddy</h1>
        </div>
    );
}

export default Error;