import React, { useState, useEffect, useRef } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { MdCancel } from "react-icons/md";

function Header(props) {
    const [toggle, setToggle] = useState(false);
    const ref = useRef();

    useEffect(() => {
        // Function to handle click outside of the menu
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setToggle(false);
            }
        }

        // Add event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function handleToggle() {
        setToggle(prev => !prev);
    }

    return (
        <div className="flex flex-row justify-around md:flex-row items-center md:justify-around">
            <div className="mt-7 md:mt-8">
                <Link to="/"> <span className="font-bold text-2xl text-blue-600">SAG.</span></Link>
            </div>

            <div className="md:flex md:flex-row md:gap-8">
                <ul className="hidden md:mt-8 md:flex flex-row gap-8 font-medium">
                    <Link to="/about">
                        <li className="hover:text-gray-500">about</li>
                    </Link>
                    <Link to="/contact">
                        <li className="hover:text-gray-500">contact</li>
                    </Link>
                    <Link to="/designs">
                        <li className="hover:text-gray-500">designs</li>
                    </Link>
                    <Link to="/guestbook">
                        <li className="hover:text-gray-500">guestbook</li>
                    </Link>
                </ul>
                {toggle ? <MdCancel onClick={handleToggle} size={25} className="cursor-pointer md:hidden mt-7" /> : <GiHamburgerMenu onClick={handleToggle} size={25} className="cursor-pointer md:hidden mt-7" />}
            </div>

            {toggle && (
                <div ref={ref} className="md:hidden absolute p-5 right-5 sm:right-20 rounded-lg top-16 sm:h-[40%] h-[40%] bg-white shadow-2xl">
                    <ul className="flex flex-col gap-8 font-medium">
                        <Link to="/about">
                            <li className="hover:text-gray-500">about</li>
                        </Link>
                        <Link to="/contact">
                            <li className="hover:text-gray-500">contact</li>
                        </Link>
                        <Link to="/designs">
                            <li className="hover:text-gray-500">designs</li>
                        </Link>
                        <Link to="/guestbook">
                            <li className="hover:text-gray-500">guestbook</li>
                        </Link>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Header;
