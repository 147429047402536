import React from 'react';
import { FaLinkedin, FaGithub, FaInstagram, FaTwitter } from "react-icons/fa";
import { BsSubstack } from "react-icons/bs";
import { FaHashnode } from "react-icons/fa6";
import { IoCopyOutline } from "react-icons/io5";

function Contact() {
    const email = "sagarc2580@gmail.com";

    const copyToClipboard = () => {
        navigator.clipboard.writeText(email).then(() => {
            alert('Email address copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy email to clipboard: ', err);
        });
    };

    // Assigning names to icons for clarity
    const LinkedinIcon = <FaLinkedin size="40" title="Linkedin" />;
    const TwitterIcon = <FaTwitter size="40" title="Twitter" />;
    const GithubIcon = <FaGithub size="40" title="Github" />;
    const InstagramIcon = <FaInstagram size="40" title="Instagram" />;
    const SubstackIcon = <BsSubstack size="40" title="Substack" />;
    const HashnodeIcon = <FaHashnode size="40" title="Hashnode" />;
    const CopyEmailIcon = <IoCopyOutline onClick={copyToClipboard} size="20" title="Copy Email" className="cursor-pointer" />;

    return (
        <div className="max-w-[80%] flex-wrap break-words text-wrap mx-auto shadow-lg h-auto sm:w-2/4 md:w-2/4 p-4 rounded-lg mt-20 border-2 flex flex-col">
            <div className="flex gap-2 mx-auto font-bold mt-3 mb-3 items-center">
                <h1 className="text-gray-500">{email}</h1>
                {CopyEmailIcon}
            </div>
            <div className="flex flex-col mx-auto gap-4">
                <a href="https://www.linkedin.com/in/vidyasagar-reddy-9478411a0" rel="noreferrer" target="_blank">{LinkedinIcon}</a>
                <a href="https://twitter.com/visualsmaker" rel="noreferrer" target="_blank">{TwitterIcon}</a>
                <a href="https://github.com/sagargouda" rel="noreferrer" target="_blank">{GithubIcon}</a>
                <a href="https://www.instagram.com/sagy.fx/?hl=en" rel="noreferrer" target="_blank">{InstagramIcon}</a>
                <a href="https://www.instagram.com/sagy.fx/?hl=en" rel="noreferrer" target="_blank">{SubstackIcon}</a>
                <a href="https://sagyr.hashnode.dev/" rel="noreferrer" target="_blank">{HashnodeIcon}</a>
            </div>
        </div>
    );
}

export default Contact;
