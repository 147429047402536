import React, {lazy , Suspense} from 'react';
import Main from "./components/Main";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Contact from "./components/Contact";
import Body from "./components/Body";
import Error from "./components/Error";
import About from "./components/About";
import Guestbook from "./components/Guestbook";


const Designs = lazy(()=> import('./components/Designs'))
function App() {
  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: <Main/>,
      errorElement: <Error/>,
      children: [
        {
          path: '/',
          element: <Body/>

        },
        {
          path: '/about',
          element: <About/>
        },

        {
          path: '/contact',
          element: <Contact/>
        },
        {
          path: '/designs',
          element: <Suspense> <Designs/></Suspense>
        },
        {
          path: '/guestbook',
          element: <Guestbook></Guestbook>
        }
      ]
    },

  ])

  return (
      <div  className={`font-poppins mx-auto `}>
        <RouterProvider router={appRouter}/>
      </div>
  );
}

export default App;
